<template>
  <div class="mailbox">
    <div class="bar bg-white lh-22">
      <div @click="selectEmail" style="width: 44%">
        邮箱<img src="@img/down.png" alt="" />
      </div>
      <div @click="selectDate">时间<img src="@img/down.png" alt="" /></div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <template v-slot:finished>
          <div class="no-data bg-white lh-22" v-if="dataList.length == 0">
            <img src="@img/no-data.png" alt="" />
            暂无数据~
          </div>
          <div v-else>没有更多了</div>
        </template>
        <div class="invoice-list">
          <div class="invoice-card" v-for="item in dataList" :key="item.id">
            <div class="head">
              {{ item.address.slice(0, 1) }}
            </div>
            <div class="info lh-22">
              <div class="email">{{ item.address }}</div>
              <div class="date">
                {{
                  new Date(item.receivedDate)
                    | dateFilter("yyyy-MM-dd hh:mm:ss")
                }}
              </div>
              <div class="desc">{{ item.subject }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <div class="btn">
      <van-button type="primary" @click="onClick">查看邮箱</van-button>
    </div>
    <CommonActionSheet
      v-model="showType"
      :actions="actions"
      cancel-text="取消"
      :closeOnClickAction="true"
      @ok="ok"
    />
    <van-popup v-model="showDate" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @cancel="showDate = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import CommonActionSheet from "../../../components/CommonActionSheet.vue";
import {
  getEmails,
  getEmailProgress,
  getRegisterTime,
} from "../../../http/api";
import moment from "moment";

export default {
  name: "email",

  components: {
    CommonActionSheet,
  },

  data() {
    return {
      showType: false,
      showDate: false,
      dataList: [],
      actions: [],
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      dateRange: [],
      loading: false,
      error: false,
      finished: false,
      refreshing: false,
      searchParams: {
        pageSize: 10,
        currentPage: 1,
      },
    };
  },

  methods: {
    selectEmail() {
      getEmails().then((res) => {
        this.actions = res.data.map((item, index) => {
          return {
            id: index,
            name: item,
          };
        });
        this.actions.unshift({
          id: null,
          name: "全部",
        });
        this.showType = true;
      });
    },

    selectDate() {
      getRegisterTime().then((res) => {
        this.minDate = new Date(res.data.createdDate);
        this.showDate = true;
      });
    },

    onLoad() {
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      getEmailProgress({ ...this.searchParams })
        .then((res) => {
          this.searchParams.currentPage++;
          this.dataList.push(...res.data.data);
          this.loading = false;
          if (this.dataList.length >= res.data.totalCount) {
            this.finished = true;
          }
        })
        .catch(() => {
          this.error = true;
        });
    },

    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.error = false;
      this.searchParams.currentPage = 1;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },

    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },

    onConfirm(val) {
      let startDate = val;
      let endDate = new Date(moment(val).endOf("month"));
      this.searchParams.dateRange = [startDate, endDate];
      this.showDate = false;
      this.refreshing = true;
      this.onRefresh();
    },

    ok(data) {
      this.searchParams.address = data.name == "全部" ? null : data.name;
      this.refreshing = true;
      this.onRefresh();
    },

    onClick() {
      this.$router.push({ name: "email-list" });
    },
  },
};
</script>

<style lang="less" scoped>
.mailbox {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  .bar {
    margin-bottom: 12px;
    padding: 12px 28px;
    display: flex;
    align-items: center;
    font-weight: 600;
    img {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      vertical-align: middle;
    }
  }
  .van-pull-refresh {
    overflow: auto;
    flex: 1;
    padding: 0 12px;
  }
  .invoice-list {
    :nth-child(odd).invoice-card {
      .head {
        background: linear-gradient(121deg, #327eff 0%, #52e3ff 100%);
      }
    }
    .invoice-card {
      display: flex;
      padding: 20px;
      .head {
        width: 36px;
        height: 36px;
        margin-right: 16px;
        border-radius: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #ffffff;
        background: linear-gradient(165deg, #5fe688 0%, #3fcb7e 100%);
      }
      .info {
        margin-top: 7px;
        .date {
          font-size: 14px;
          color: #999999;
        }
        .desc {
          margin-top: 12px;
          font-size: 14px;
          color: #666666;
        }
      }
    }
    .no-data {
      padding: 27px 0 24px;
      border-radius: 4px;
      text-align: center;
      color: #999999;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 200px;
        height: 140px;
        display: block;
        margin-bottom: 5px;
      }
    }
  }
  .btn {
    width: 100%;
    text-align: center;
    button {
      width: 295px;
      height: 40px;
      margin: 16px 0;
      border-radius: 20px;
      background: #33c759;
      font-size: 16px;
    }
  }
}
</style>
